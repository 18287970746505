@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font-family: 'Poppins', sans-serif;
}

.App {
  position: relative;
  overflow-x: clip;
}

.App>:nth-child(1) {
  background: var(--black);
  position: relative;
}

.white-gradient {
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgb(255, 255, 255, 0.522);
  filter: blur(100px);
  border-radius: 100px;
}